<template>
   <!-- <van-button type="primary">sdfsd</van-button> -->
  <template v-if="(typeof product.productId !== 'undefined')">
    <div style="margin:15px;">
      <div style="padding:15px;margin:0 0 7px 0;background:#fff;" v-if="salespersonId !== ''">
        <small>您是本店店员，享受销售提成</small>
      </div>
      <div id="share-card" v-show="show" style="background:#fff;">
        <template v-if="product.posterImage !== ''">
          <div style="position:relative;">
            <van-image width="100%" :src="product.posterImage + ''" />
            <van-row>
              <van-col span="3">
                <van-image
                  :src="avatar"
                  round
                  width="2rem"
                  height="2rem"
                  style="margin: 20px 0 0 5px;"
                />
              </van-col>
              <van-col span="16">
                <div style="margin:15px 0 0 5px;">
                  <p>
                    <small>
                      {{ nickname }}
                    </small>
                  </p>
                  <p>
                    <small>
                      {{ product.store.name }}
                    </small>
                  </p>
                </div>
              </van-col>
              <van-col span="5">
                <van-image :src="qr" width="100%" />
              </van-col>
            </van-row>
          </div>
        </template>
        <template v-else>
          <div style="padding:10px;" v-if="nickname !== '' && avatar !== ''">
            <van-image
              :src="avatar"
              round
              width="2rem"
              height="2rem"
              style="vertical-align: middle;"
            />
            <span style="margin:0 0 0 3px;">
              <small>
                {{ nickname }}
              </small>
            </span>
          </div>
          <van-image width="100%" :src="product.image + '?imageView2/1/w/600/h/600/q/100'" />
          <van-row>
            <van-col span="16">
              <p style="margin:10px 0 0 10px;">
                <small>
                  {{ product.name.substring(0, 30) }}
                </small>
              </p>
              <p style="margin:5px 0 5px 10px;">
                <price :price="product.price" :scale="1" />
              </p>
            </van-col>
            <van-col span="8">
              <van-image width="100%" :src="qr" />
            </van-col>
          </van-row>
        </template>
      </div>
      <van-image :src="shareCardImage" v-show="!show"/>
      <p style="margin:7px 0 0 0;">
        <small>长按图片保存到相册</small>
      </p>
    </div>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'
import { Toast } from 'vant'
import Price from '@/components/Price'

export default {
  components: {
    Price
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    // const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      qr: '',
      buyer: {},
      product: {},
      show: true,
      shareCardImage: '',
      grouponNumber: useRoute.query.grouponNumber ?? '',
      buyerId: Cookies.get('buyerId'),
      storeId: Cookies.get('storeId'),
      userId: Cookies.get('userId'),
      productId: useRoute.query.productId,
      salespersonId: '',
      avatar: '',
      nickname: '',
      task: 0
    })
    const onClick = (obj) => {
    }
    const buildImage = (content) => {
      Toast.loading({ duration: 0, forbidClick: true })
      QRCode.toDataURL(content).then(image => {
        state.qr = image
        setTimeout(() => {
          html2canvas(
            document.querySelector('#share-card'),
            {
              useCORS: true,
              dpi: 300,
              onrendered: function (canvas) {
              }
            }
          ).then(canvas => {
            Toast.clear()
            state.shareCardImage = canvas.toDataURL()
            state.show = false
          }).catch(err => {
            Toast('生成图片出错啦' + err)
          })
        }, 1000)
      })
    }
    const buildUrl = () => {
      var url = window.location.protocol + '//' + window.location.host + '/product/detail?productId=' + state.product.productId
      url = url + '&inviterId=' + state.buyerId
      url = url + '&storeId=' + state.storeId
      if (state.salespersonId !== '') {
        url = url + '&salespersonId=' + state.salespersonId
      }
      if (state.grouponNumber !== '') {
        url = url + '&grouponNumber=' + state.grouponNumber
      }
      console.log(url)
      buildImage(url)
      // if (state.grouponId !== '') {
      //   url = url + '&groupon_id=' + state.grouponId
      //   getGroupon({
      //     grouponId: state.grouponId
      //   }).then(res => {
      //     state.groupon = res.data
      //     console.log(res.data)
      //     buildImage(url)
      //   })
      // }
      // if (state.salespersonId !== '') {
      //   url = url + '&salesperson_id=' + state.salespersonId
      //   buildImage(url)
      // }
      // if (state.inviterId !== '') {
      //   url = url + '&inviter_id=' + state.inviterId
      //   buildImage(url)
      // }
      // console.log(url)
      // console.log(state.grouponId)
    }
    const init = () => {
      post('/product.get', {
        productId: state.productId
      }).then(res => {
        if (res.code === 0) {
          state.product = res.data
        }
        state.task++
      })
      post('/buyer.get', {
        buyerId: state.buyerId
      }).then(res => {
        if (res.code === 0) {
          state.nickname = res.data.name
          state.avatar = res.data.avatar
        }
        state.task++
      })
      post('/staff.get', {
        storeId: state.storeId,
        userId: state.userId
      }).then(res => {
        if (res.code === 0) {
          state.salespersonId = res.data.staffId
        }
        state.task++
      })
    }
    onMounted(() => {
      init()
    })
    watch(() => state.task, (to, previous) => {
      if (to === 3) {
        buildUrl()
      }
    })
    return {
      ...toRefs(state),
      onClick
    }
  }
}
</script>

<style>
/* .van-button--primary {
  background-color: #ff6600;
} */
/* 可能是 vant 包含了 apple-system 字体，但是ios15下该字体存在兼容性问题，导致html2canvas错误 */
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
:root {
  --van-button-primary-background-color: red;
  --van-base-font-family: BlinkMacSystemFont, 'Helvetica Neue',
  Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB',
  'Microsoft Yahei', sans-serif;
}
/* html {
  font-family: Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei', SimHei;
}
button {
  font-family: Helvetica
} */
/* .mx-box {
  background: #fff;
  padding:15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
img {
  width:100%;
  vertical-align: bottom;
}
img.mx-goods-image {
  vertical-align: middle;
  width:100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.mx-save{
  margin:20px 0 0 0;
}
.mx-card-image >>> img{
  width:100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */
</style>
