<template>
  <span :style="{ 'color': priceColor, 'font-size': priceSize * 0.8 + 'rem' }">{{ priceUnit }}</span>
  <span :style="{ 'color': priceColor, 'font-size': priceSize * 1.2 + 'rem' }">{{ priceValue.toFixed(priceScale) / 1 }}</span>
  <span :style="{ 'color': priceColor, 'font-size': priceSize * 0.8 + 'rem' }" v-if="priceRightText !== ''">&nbsp;{{ priceRightText }}</span>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  props: {
    symbol: {
      type: String,
      default: '￥'
    },
    color: {
      type: String,
      default: '#ff5000'
    },
    size: {
      type: String,
      default: 'normal'
    },
    price: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 2
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const state = reactive({
      priceUnit: props.symbol,
      priceValue: props.price,
      priceColor: props.color,
      priceSize: 0,
      priceScale: props.scale,
      priceRightText: props.rightText
    })
    const init = () => {
      console.log(':::::::::::')
      console.log(props.price)
      if (props.size === 'mini') {
        state.priceSize = 0.6 * 1.0
      } else if (props.size === 'small') {
        state.priceSize = 0.8 * 1.0
      } else if (props.size === 'normal') {
        state.priceSize = 1 * 1.0
      } else if (props.size === 'large') {
        state.priceSize = 1.2 * 1.0
      } else {
      }
      // val.toFixed(n)/1  js保留几位小数，小数为0的省略
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
</style>
